<template>
  <div style="height: inherit">
    <page-loader v-if="loading" type="list" :fluid="true" />
    <portal to="nav-bar-left-column">
      <page-title title="GraphQL" subtitle="Compose and Test you GraphQL queries" />
    </portal>
    <iframe ref="graphqlContainer" class="w-100 h-100"></iframe>
  </div>
</template>

<script>
import axios from 'axios'
import page from 'raw-loader!./graphiql.html'

export default {
  components: {},

  data() {
    return {
      loading: false,
    }
  },
  computed: {},
  mounted() {
    // const instance = axios.create({
    //   baseURL: '/',
    //   timeout: 1000,
    // })
    // let doc = await instance.get('/graphiql-playground').then(response => {
    //   let html = response.data
    //   return html.replace('{__token__}', self.$store.getters.User.access_token)
    // })

    const self = this
    let doc = page.replace('{__token__}', this.$store.getters.User.access_token)
    self.$refs.graphqlContainer.contentWindow.document.open()
    self.$refs.graphqlContainer.contentWindow.document.write(doc)
    self.$refs.graphqlContainer.contentWindow.document.close()
  },
  methods: {},
}
</script>
<style lang="scss">
.graphql-application {
  &.app-content {
    height: 100vh !important;
    padding: calc(2rem + 2.5rem) 0 0 0 !important;
  }
  .content-wrapper {
    height: calc(100vh - 5rem);
    .content-body {
      height: 100%;
    }
  }
  iframe {
    border: none;
  }
}
</style>
